import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import CollectionPage from '~/components/Collection/Page'

const CollectionTemplate = ({ data, path }) => {
  const { collection } = data

  return (
    collection && (
      <Layout breadcrumb={{ crumbLabel: collection.title }}>
        <Metadata
          title={collection.title}
          description={
            collection.description.childMarkdownRemark.rawMarkdownBody
          }
        />
        <CollectionPage path={path} collection={collection} />
      </Layout>
    )
  )
}

export const query = graphql`
  query CollectionQuery($slug: String, $locale: String) {
    collection: contentfulCollection(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...CollectionPageFragment
    }
  }
`

export default CollectionTemplate
