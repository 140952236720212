import React from 'react'
import { Box, Button } from 'theme-ui'

export function DTSortbuttons({ activeTab, priceHighToLow, priceLowtoHigh }) {
  return (
    <Box
      sx={{
        display:
          activeTab === 'sort' ? ['none', 'none', 'flex', 'flex'] : 'none',
        marginTop: ['15px'],
        justifyContent: 'flex-end'
      }}
    >
      <Button
        onClick={priceHighToLow}
        sx={{
          variant: 'buttons.select',
          '&:hover': {
            backgroundColor: 'grey70'
          },
          ':not(:last-of-type)': {
            marginRight: 3
          }
        }}
      >
        Price (high to low)
      </Button>
      <Button
        onClick={priceLowtoHigh}
        sx={{
          variant: 'buttons.select',
          '&:hover': {
            backgroundColor: 'grey70'
          }
        }}
      >
        Price (low to high)
      </Button>
    </Box>
  )
}
