/** @jsx jsx */
import { useState, useEffect, Fragment, useContext } from 'react'
import { DTSortbuttons } from './DTSortbuttons'
import { Flex, Box, Button, Heading, jsx, useThemeUI } from 'theme-ui'
import queryString from 'query-string'
import _ from 'lodash'
import { useLocation } from '@reach/router'
import { FilterButtons } from './FilterButtons.jsx'
import CollapsiblePanelAlternate from '~/components/Generic/CollapsiblePanel/Alternate'
import FilterContext from '~/contexts/FilterContext'
import Sorting from './Sorting.jsx'

const CollectionFilterBar = ({
  products,
  renderedProducts,
  setRenderedProducts,
  setCustomTitle = () => false
}) => {
  const { theme } = useThemeUI()
  const [activeTab, setActiveTab] = useState(null)
  const [activeFilters, setActiveFilters] = useContext(FilterContext)

  const [filters] = useState(() => {
    const result = {}

    _.forEach(products, product => {
      _.forEach(product.variants, variant => {
        _.forEach(variant.filterValues, fv => {
          if (fv.filter_category) {
            const name = fv.filter_category[0].name
            const value = fv.presentation
            // Check if prop exist in result
            // if exists, just add to set
            if (result[name]) {
              result[name].options.add(value)
              // If not, create and populate
            } else {
              result[name] = {
                title: name,
                options: new Set([value])
              }
            }
          }
        })
      })
    })

    return result
  })

  // This function checks for price ranges and modifies them to only return the first value so we can sort

  const getSortableNumVal = val => {
    if (val.includes('$')) {
      return val.replace(/\$/g, '').split('-')[0]
    } else return val
  }
  const [availableFilters, setAvailableFilters] = useState([])

  const priceHighToLow = () => {
    const sorted = _.sortBy(renderedProducts, [
      p => (p.variants && p.variants[0] ? -p.variants[0].price : 1)
    ])
    setRenderedProducts(sorted)
  }

  const priceLowtoHigh = () => {
    const sorted = _.sortBy(renderedProducts, [
      p => (p.variants && p.variants[0] ? p.variants[0].price : 1)
    ])
    setRenderedProducts(sorted)
  }

  const handleSelect = value => {
    setCustomTitle(null)
    if (activeFilters.find(v => v === value)) {
      setActiveFilters(() => activeFilters.filter(v => v !== value))
    } else {
      setActiveFilters([...activeFilters, value])
    }
  }

  useEffect(() => {
    // check if any variants for each product contain all of the filters
    const repopulateProducts = () => {
      const filteredProducts = []
      const availableFilters = []
      _.forEach(products, product => {
        _.forEach(product.variants, variant => {
          const filtersArray = _.map(
            variant.filterValues,
            ov => ov.presentation
          )

          if (activeFilters.every(value => filtersArray.includes(value))) {
            filteredProducts.push(product)

            availableFilters.push(filtersArray)
          } else {
            // push only the options that don't exist on currently filtered products
          }
        })
      })

      if (_.size(activeFilters)) {
        setRenderedProducts(_.uniqWith(filteredProducts, _.isEqual))
        setAvailableFilters(_.uniqWith(_.flatten(availableFilters), _.isEqual))
      } else {
        setRenderedProducts(products)
        setAvailableFilters([])
      }
    }
    repopulateProducts()
  }, [activeFilters, products, setRenderedProducts])

  const location = useLocation()
  const parsed = queryString.parse(location.search)

  useEffect(() => {
    if (parsed.filters) {
      const splitFilters = parsed.filters.split(',')
      if (splitFilters.length === 1 && parsed.byModel) {
        setCustomTitle(`Custom parts and accessories for ${splitFilters[0]}`)
      } else {
        setCustomTitle(null)
      }
      setActiveFilters([...activeFilters, ...splitFilters])
    } else {
      setCustomTitle(null)
    }
  }, [])

  return (
    <Fragment>
      <Box
        sx={{
          marginTop: ['1.6rem', null, '-2.25rem', '-3.5rem'],
          button: {
            px: ['1rem', '2rem'],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ':disabled': {
              opacity: 0.3
            },
            ':hover': {
              color: 'black !important'
            }
          }
        }}
      >
        <FilterButtons activeTab={activeTab} setActiveTab={setActiveTab} />
        {/** Laptop/Desktop Only */}
        <Box sx={{ variant: 'responsive.smallLaptopUp' }}>
          <DTSortbuttons
            activeTab={activeTab}
            priceHighToLow={priceHighToLow}
            priceLowtoHigh={priceLowtoHigh}
          />
          <Box
            sx={{
              display: activeTab === 'filters' ? 'block' : 'none',
              marginTop: ['15px']
            }}
          >
            <Flex
              sx={{
                justifyContent: 'center'
              }}
            >
              {_.map(filters, f => (
                <Flex
                  key={`filter-${f.title}`}
                  sx={{
                    flexDirection: 'column',
                    marginRight: [null, null, '10px', '100px'],
                    alignItems: 'center'
                  }}
                >
                  <Heading
                    as="h3"
                    sx={{
                      variant: 'text.small',
                      fontWeight: 'bold',
                      fontSize: [null, null, '1.1rem', '1.5rem']
                    }}
                  >
                    {f.title}
                  </Heading>
                  <Flex sx={{ flexDirection: 'column' }}>
                    {/**
                     *  Map over each filter category, convert the options to
                     *  an array (from a set), and then sort it in descending order
                     */}
                    {_.map(
                      Array.from(f.options.values()).sort((a, b) => {
                        return getSortableNumVal(a) - getSortableNumVal(b)
                      }),
                      value => (
                        <Button
                          onClick={() => handleSelect(value)}
                          key={`${f.title}-${value}`}
                          disabled={
                            _.size(availableFilters) &&
                            !availableFilters.includes(value)
                          }
                          sx={{
                            variant: 'buttons.select',
                            fontSize: [null, null, '1.1rem', '1.5rem'],
                            color:
                              _.size(availableFilters) &&
                              !availableFilters.includes(value)
                                ? 'grey'
                                : 'black',
                            pointerEvents:
                              _.size(availableFilters) &&
                              !availableFilters.includes(value)
                                ? 'none'
                                : '',
                            backgroundColor: activeFilters.find(
                              v => v === value
                            )
                              ? 'grey70'
                              : 'transparent',
                            fontWeight: activeFilters.find(v => v === value)
                              ? 'bold'
                              : 'body',
                            '&:hover': { backgroundColor: 'grey70' }
                          }}
                        >
                          {value}
                        </Button>
                      )
                    )}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Box>
        </Box>
        {/** ** Mobile/Tablet Only ** */}
        <Box
          sx={{
            variant: 'responsive.mobileTablet',
            fontSize: [0, 1],
            marginTop: ['15px']
          }}
        >
          {/** Sorting */}
          <Sorting
            activeTab={activeTab}
            priceHighToLow={priceHighToLow}
            priceLowtoHigh={priceLowtoHigh}
          />
          {/** Filtering */}
          <Box
            sx={{
              display: activeTab === 'filters' ? 'block' : 'none'
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column'
              }}
            >
              {_.map(filters, f => (
                <Flex
                  key={`filter-${f.title}`}
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderBottom: `1px solid ${theme.colors.border}`,
                    pb: 2
                  }}
                >
                  <CollapsiblePanelAlternate title={f.title}>
                    <Flex
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap'
                      }}
                    >
                      {/**
                       *  Map over each filter category, convert the options to
                       *  an array (from a set), and then sort it in descending order
                       */}
                      {_.map(
                        Array.from(f.options.values()).sort((a, b) => {
                          return getSortableNumVal(a) - getSortableNumVal(b)
                        }),
                        value => (
                          <Button
                            onClick={() => handleSelect(value)}
                            key={`${f.title}-${value}`}
                            disabled={
                              _.size(availableFilters) &&
                              !availableFilters.includes(value)
                            }
                            sx={{
                              color:
                                _.size(availableFilters) &&
                                !availableFilters.includes(value)
                                  ? 'grey'
                                  : 'black',
                              pointerEvents:
                                _.size(availableFilters) &&
                                !availableFilters.includes(value)
                                  ? 'none'
                                  : '',
                              backgroundColor: activeFilters.find(
                                v => v === value
                              )
                                ? 'grey70'
                                : 'transparent',
                              fontWeight: activeFilters.find(v => v === value)
                                ? 'bold'
                                : 'body',
                              '&:hover': { backgroundColor: 'grey70' }
                            }}
                          >
                            {value}
                          </Button>
                        )
                      )}
                    </Flex>
                  </CollapsiblePanelAlternate>
                </Flex>
              ))}
            </Flex>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default CollectionFilterBar
