/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box, Container, Heading, jsx } from 'theme-ui'

import ProductList from '~/components/Product/List'
import CollectionFilterBar from '~/components/Collection/FilterBar'
import { Link } from 'gatsby'

const CollectionPage = ({ collection }) => {
  const { title, products, lookbook } = collection
  const [renderedProducts, setRenderedProducts] = useState(products)
  const parentPath =
    collection.lineup?.length &&
    collection.lineup[0] &&
    collection.lineup[0]?.lineup
      ? collection.lineup[0].lineup[0].slug
      : collection.lineup[0].slug

  const [customTitle, setCustomTitle] = useState(' ')

  return (
    <Container
      sx={{
        pt: ['var(--inset-top)', 'var(--inset-top)'],
        pb: [5, null, 5]
      }}
    >
      <Box sx={{ marginBottom: ['4rem', '8rem'] }}>
        <Heading
          as="h2"
          sx={{
            fontSize: [5, null, null, customTitle ? 6 : 8],
            fontWeight: 'medium',
            textTransform: 'uppercase'
          }}
        >
          {customTitle || title}
        </Heading>
        {lookbook && (
          <div sx={{ marginTop: '7px' }}>
            <Link
              sx={{
                fontSize: [2, null, null, 5],
                fontWeight: 'body',
                textDecoration: 'underline'
              }}
              to={`/${parentPath === 'collections' ? 'apparel' : parentPath}/${
                collection.slug
              }/lookbook`}
            >
              VIEW THE LOOKBOOK
            </Link>
          </div>
        )}
        <CollectionFilterBar
          products={products}
          renderedProducts={renderedProducts}
          setRenderedProducts={setRenderedProducts}
          setCustomTitle={setCustomTitle}
        />
      </Box>

      {products && (
        <ProductList
          collectionName={collection.name ?? 'no name collection'}
          products={renderedProducts}
        />
      )}
    </Container>
  )
}

CollectionPage.propTypes = {
  path: PropTypes.string.isRequired,
  collection: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.shape({}),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string,
        slug: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.number,
        mainImage: PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string
        }),
        images: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string
          })
        )
      })
    ),
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  })
}

export default CollectionPage
