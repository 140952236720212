import React from 'react'
import { Flex, Button } from 'theme-ui'

const Sorting = ({ activeTab, priceHighToLow, priceLowtoHigh }) => {
  return (
    <Flex
      sx={{
        display: activeTab === 'sort' ? 'flex' : 'none',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        flexWrap: 'wrap'
      }}
    >
      <Button
        onClick={priceHighToLow}
        sx={{
          variant: 'buttons.select',
          '&:hover': {
            backgroundColor: 'grey70'
          }
        }}
      >
        Price (high to low)
      </Button>
      <Button
        onClick={priceLowtoHigh}
        sx={{
          variant: 'buttons.select',
          '&:hover': {
            backgroundColor: 'grey70'
          }
        }}
      >
        Price (low to high)
      </Button>
    </Flex>
  )
}

export default Sorting
