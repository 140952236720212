import React from 'react'
import PlusIcon from '~/assets/images/icons/plus-icon.svg'
import PlusIconBold from '~/assets/images/icons/plus-icon-bold.svg'
import { Box, Flex, Button, Text } from 'theme-ui'

export const FilterButtons = ({ activeTab, setActiveTab }) => {
  return (
    <Flex
      sx={{
        justifyContent: 'flex-end'
      }}
    >
      <Button
        variant="buttons.select"
        onClick={() => {
          if (activeTab === 'filters') {
            setActiveTab(null)
          } else {
            setActiveTab('filters')
          }
        }}
        sx={{
          cursor: 'pointer'
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            '& > svg': {
              mr: ['11px', '15px', '24px']
            }
          }}
        >
          <Text
            sx={{
              fontWeight: activeTab === 'filters' ? 'bold' : 'medium'
            }}
          >
            FILTERS
          </Text>
          <Box
            sx={{
              '& > svg': {
                display: activeTab === 'filters' ? 'none' : 'inline-block'
              }
            }}
          >
            <PlusIcon />
          </Box>
          <Box
            sx={{
              '& > svg': {
                display: activeTab === 'filters' ? 'inline-block' : 'none'
              }
            }}
          >
            <PlusIconBold
              sx={{
                display: activeTab === 'filters' ? 'inline-block' : 'none'
              }}
            />
          </Box>
        </Flex>
      </Button>
      <Button
        variant="buttons.select"
        onClick={() => {
          if (activeTab === 'sort') {
            setActiveTab(null)
          } else {
            setActiveTab('sort')
          }
        }}
        sx={{
          cursor: 'pointer'
        }}
      >
        <Flex
          sx={{
            alignItems: 'center'
          }}
        >
          <Text
            sx={{
              fontWeight: activeTab === 'sort' ? 'bold' : 'medium'
            }}
          >
            SORT
          </Text>
          <Box
            sx={{
              '& > svg': {
                display: activeTab === 'sort' ? 'none' : 'inline-block'
              }
            }}
          >
            <PlusIcon />
          </Box>
          <Box
            sx={{
              '& > svg': {
                display: activeTab === 'sort' ? 'inline-block' : 'none'
              }
            }}
          >
            <PlusIconBold />
          </Box>
        </Flex>
      </Button>
    </Flex>
  )
}
