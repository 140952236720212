/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box, Flex, jsx, Text } from 'theme-ui'

import PlusIconBold from '~/assets/images/icons/plus-icon-bold.svg'

const CollapsiblePanelAlternate = ({
  title,
  titleColor = 'primary',
  children,
  initiallyCollapsed = true
}) => {
  const [collapsed, setCollapsed] = useState(initiallyCollapsed)
  const toggleCollapse = () => setCollapsed(!collapsed)

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Box
        sx={{
          py: ['5px'],
          textAlign: 'left',
          display: 'flex',
          alignItems: 'center',
          '&:focus': {
            outline: 'none'
          }
        }}
      >
        <button
          onClick={toggleCollapse}
          sx={{
            backgroundColor: 'transparent',
            border: 'none',
            padding: '0 !important'
          }}
        >
          <Text
            sx={{
              color: titleColor,
              textTransform: ['uppercase'],
              display: 'inline-block',
              textAlign: 'left',
              marginRight: '5px',
              fontSize: '1rem',
              fontFamily: 'body',
              letterSpacing: '1px'
            }}
          >
            {title}
          </Text>
        </button>

        <PlusIconBold
          onClick={toggleCollapse}
          sx={{
            position: 'relative',
            right: '0',
            transform: collapsed ? 'rotate(0deg)' : 'rotate(45deg)',
            transition: 'transform 0.25s ease'
          }}
        />
      </Box>
      <Box
        data-testid="collapsible-content"
        sx={{
          overflow: 'hidden',
          maxHeight: collapsed ? 0 : '2000px',
          transition: 'max-height 0.25s ease'
        }}
      >
        <Text
          sx={{
            paddingLeft: ['16px'],
            paddingRight: ['16px'],
            textAlign: 'left'
          }}
        >
          {children}
        </Text>
      </Box>
    </Flex>
  )
}

CollapsiblePanelAlternate.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  initiallyCollapsed: PropTypes.bool,
  titleColor: PropTypes.string
}

export default CollapsiblePanelAlternate
